import type { ReactNode } from 'react';

import clsx from 'clsx';

export interface ProseProps {
  children?: ReactNode;
  className?: string;
}

export function Prose(props: ProseProps) {
  const { children, className } = props;

  return (
    <div className={clsx(className, 'prose dark:prose-invert')}>{children}</div>
  );
}
