import { forwardRef, type ReactNode } from 'react';

import clsx from 'clsx';

interface OuterContainerProps {
  className?: string;
  children?: ReactNode;
}

const OuterContainer = forwardRef<HTMLDivElement, OuterContainerProps>(
  function OuterContainer(props, ref) {
    const { className, children } = props;

    return (
      <div
        ref={ref}
        className={clsx('sm:px-8', className)}
        {...props}
      >
        <div className='mx-auto max-w-7xl lg:px-8'>{children}</div>
      </div>
    );
  },
);

interface InnerContainerProps {
  className?: string;
  children?: ReactNode;
}

const InnerContainer = forwardRef<HTMLDivElement, InnerContainerProps>(
  function InnerContainer(props, ref) {
    const { className, children } = props;

    return (
      <div
        ref={ref}
        className={clsx('relative px-4 sm:px-8 lg:px-12', className)}
        {...props}
      >
        <div className='mx-auto max-w-2xl lg:max-w-5xl'>{children}</div>
      </div>
    );
  },
);

export interface ContainerProps
  extends OuterContainerProps,
    InnerContainerProps {}

export const Container = forwardRef<HTMLDivElement, ContainerProps>(
  function Container(props, ref) {
    const { children } = props;

    return (
      <OuterContainer
        ref={ref}
        {...props}
      >
        <InnerContainer>{children}</InnerContainer>
      </OuterContainer>
    );
  },
);

// @ts-expect-error - not sure how to type this
Container.Outer = OuterContainer;
// @ts-expect-error - not sure how to type this
Container.Inner = InnerContainer;
