import { Container, Prose } from '@sanguine/ui-components';

const IndexPage = () => {
  return (
    <Container>
      <Prose>Coming soon!</Prose>
    </Container>
  );
};

export default IndexPage;
